<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Table Saw Queue
        <div>
            <button mat-mini-fab color="primary" (click)="refresh()" matTooltip="Refresh">
                <mat-icon>refresh</mat-icon>
            </button>
            <button *ngIf="!fullScreenMode" mat-mini-fab color="primary" (click)="setFullScreenMode()"
                matTooltip="Fullscreen Mode">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button *ngIf="fullScreenMode" mat-mini-fab color="primary" (click)="exitFullScreenMode()"
                matTooltip="Exit Fullscreen Mode">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
        </div>
    </h1>
    <hr />

    <div class="container">
        <mat-accordion multi="true">
            <mat-expansion-panel expanded="true" class="mat-elevation-z0">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Production Location
                    </mat-panel-title>
                    <mat-panel-description>
                        Select a production location
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="actions">
                    <mat-form-field>
                        <mat-label>Production Location</mat-label>
                        <mat-select (selectionChange)="onProductionLocationChange()" [(value)]="productionLocationId">
                            <mat-option *ngFor="let item of productionLocations" [value]="item.id">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="machine" [class.error]="errorGettingMachine">
                        <p>Machine</p>
                        <span>{{machine}}</span>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel expanded="true" class="filters-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Filters
                    </mat-panel-title>
                    <mat-panel-description>
                        Filter production orders
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="filters">
                    <div class="filters-container">
                        <div>
                            <mat-form-field>
                                <input matInput placeholder="PO #" [formControl]="poNumberFilter">
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker" placeholder="Production Date"
                                    [formControl]="productionDateFilter" (dateChange)="onProductionDateFilterChange()">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Colors</mat-label>
                                <mat-select [formControl]="colorsFilter" multiple>
                                    <mat-option *ngFor="let color of colors" [value]="color.name">{{color.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>

    <button class="scroll-down" mat-mini-fab color="primary" (click)="scrollToDown()" matTooltip="Scroll to Bottom">
        <mat-icon>arrow_downward</mat-icon>
    </button>

    <button class="scroll-up" mat-mini-fab color="primary" (click)="scrollToTop()" matTooltip="Scroll to Top">
        <mat-icon>arrow_upward</mat-icon>
    </button>

    <div class="reset-action-container" *ngIf="!loading && filtersApplied">
        <button mat-raised-button (click)="clearFilters()">
            <mat-icon>clear</mat-icon> Reset Production Orders Filters
        </button>
    </div>

    <h4 *ngIf="!loading && !hasProductionOrders()">No production orders to show.</h4>

    <table mat-table *ngIf="!loading && hasProductionOrders()" [dataSource]="getProductionOrdersDataSource()"
        #productionOrdersSort="matSort" matSort class="mat-elevation-z0 table">
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row [ngClass]="{'started': row.gibenStartTime}"
            *matRowDef="let row; let i = index; columns: displayedColumns;">
        </tr>

        <ng-container matColumnDef="productionDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Production Date </th>
            <td mat-cell *matCellDef="let element"> {{element.productionDateStr}}</td>
        </ng-container>

        <ng-container matColumnDef="productionOrderNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PO # </th>
            <td mat-cell *matCellDef="let element">
                <a mat-button color="accent" matTooltip="Apartments with this PO#" matTooltipPosition="above"
                    routerLink="/apartments/{{element.productionOrderNumber}}">
                    <i class="material-icons">open_in_new</i> {{element.productionOrderNumber}}
                </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
            <td mat-cell *matCellDef="let element"> {{element.color}}</td>
        </ng-container>

        <ng-container matColumnDef="styles">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Styles </th>
            <td mat-cell *matCellDef="let element"> {{element.styles}}</td>
        </ng-container>

        <ng-container matColumnDef="materialSupplier">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Material Supplier </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="needToDisplayMaterialSupplier(element)" class="material-supplier"
                    [ngClass]="element.materialSupplierColor">
                    {{element.materialSupplierStr}}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="numberOfParts">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Parts</th>
            <td mat-cell *matCellDef="let element">
                {{element.numberOfParts}}
                <span *ngIf="element.onHold" class="hold">hold</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="fusion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fusion</th>
            <td mat-cell *matCellDef="let element"> {{element.isFusion | active}}</td>
        </ng-container>

        <ng-container matColumnDef="wip">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> WIP</th>
            <td mat-cell *matCellDef="let element"> {{element.wip | active}}</td>
        </ng-container>

        <ng-container matColumnDef="notes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Notes</th>
            <td mat-cell *matCellDef="let element"> {{element.gibenNotes}}</td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
            <td mat-cell *matCellDef="let element"> {{element.friendlyStatus}}</td>
        </ng-container>

        <ng-container matColumnDef="print">
            <th mat-header-cell *matHeaderCellDef>Print</th>
            <td mat-cell *matCellDef="let element">
                <button color="accent" [disabled]="disableProductionOrderActions(element) || element.paperworkPrinted"
                    mat-icon-button matTooltip="Print Paperwork" (click)="printPaperwork(element.id)"
                    matTooltipPosition="above">
                    <mat-icon>print</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
                <button color="accent" mat-icon-button matTooltip="Edit Production Order" matTooltipPosition="above"
                    (click)="openEditProductionOrder(element.id)">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>
    </table>
</mat-card>