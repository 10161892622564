<mat-card>
    <h1 class="mat-h1">
        What's Not

        <div>
            <mat-slide-toggle checked="true" labelPosition="before" (change)="toggleAutoRefresh($event)">
                Auto Refresh
            </mat-slide-toggle>
            <button mat-mini-fab color="primary" (click)="refresh()" matTooltip="Refresh">
                <mat-icon>refresh</mat-icon>
            </button>
            <button mat-mini-fab color="primary" (click)="openBottomSheetScheduleLegend()" matTooltip="Schedule Legend">
                <mat-icon>subject</mat-icon>
            </button>
            <button *ngIf="!fullScreenMode" mat-mini-fab color="primary" (click)="setFullScreenMode()"
                matTooltip="Fullscreen Mode">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button *ngIf="fullScreenMode" mat-mini-fab color="primary" (click)="exitFullScreenMode()"
                matTooltip="Exit Fullscreen Mode">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
        </div>
    </h1>
    <hr />

    <mat-accordion>
        <mat-expansion-panel expanded="true" class="filters-panel">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Filters
                </mat-panel-title>
                <mat-panel-description>
                    Filter What's Not
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="filters">
                <div class="filters-container">
                    <div>
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker1" placeholder="Production Date"
                                [formControl]="productionDateFilter">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Production Status</mat-label>
                            <mat-select [formControl]="productionStatusFilter"
                                (selectionChange)="onMultipleSelectionChange($event)" multiple>
                                <mat-option value="show-all">-- Show All --</mat-option>
                                <mat-option *ngFor="let key of productionStatusKeys" [value]="key">
                                    {{productionStatuses.get(key)}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-select placeholder="Color" [formControl]="colorFilter"
                                (selectionChange)="onMultipleSelectionChange($event)" multiple>
                                <mat-option value="show-all">-- Show All --</mat-option>
                                <mat-option *ngFor="let color of colors" [value]="color.name">{{color.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-select placeholder="Style" [formControl]="doorStyleFilter"
                                (selectionChange)="onMultipleSelectionChange($event)" multiple>
                                <mat-option value="show-all">-- Show All --</mat-option>
                                <mat-option *ngFor="let style of doorStyles" [value]="style.name">{{style.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput placeholder="SO #" [formControl]="salesOrderNumberFilter">
                        </mat-form-field>
                    </div>
                    <div>
                        <app-multi-select-autocomplete class="mat-autocomplete" [placeholder]="'PO #'"
                            [options]="productionOrderNumbers" ngDefaultControl
                            [formControl]="productionOrderNumbersFilter" [display]="'value'" [value]="'value'"
                            [multiple]='true' [labelCount]="10">
                        </app-multi-select-autocomplete>

                        <mat-form-field>
                            <mat-label>Production Location</mat-label>
                            <mat-select [(value)]="productionLocationId">
                                <mat-option *ngFor="let item of productionLocations" [value]="item.id">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <input matInput placeholder="Customer" [formControl]="customerFilter">
                        </mat-form-field>

                        <mat-form-field>
                            <input matInput placeholder="Community" [formControl]="communityFilter">
                        </mat-form-field>

                        <mat-form-field>
                            <input matInput placeholder="Apt #" [formControl]="apartmentFilter">
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field>
                            <mat-select placeholder="Ship Method" [formControl]="shipMethodFilter"
                                (selectionChange)="onMultipleSelectionChange($event)" multiple>
                                <mat-option value="show-all">-- Show All --</mat-option>
                                <mat-option *ngFor="let method of shipMethods" [value]="method">
                                    {{method}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-checkbox [formControl]="accessoriesOnlyFilter">
                            Accessories Only
                        </mat-checkbox>

                    </div>
                </div>


                <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()">
                    <mat-icon>filter_list</mat-icon>
                </button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <br />

    <div class="reset-action-container" *ngIf="filtersApplied">
        <button mat-raised-button (click)="clearFilters()">
            <mat-icon>clear</mat-icon> Reset What's Not Filters
        </button>
    </div>

    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>

    <button class="scroll-down" mat-mini-fab color="primary" (click)="scrollToDown()" matTooltip="Scroll to Bottom">
        <mat-icon>arrow_downward</mat-icon>
    </button>

    <button class="scroll-up" mat-mini-fab color="primary" (click)="scrollToTop()" matTooltip="Scroll to Top">
        <mat-icon>arrow_upward</mat-icon>
    </button>

    <h3 *ngIf="!loading && getData().length === 0"> No production orders to show.</h3>

    <div class="main-bottom-toolbar">
        <div class="section selection-summary mat-elevation-z2">
            <p><strong>Slab Parts: </strong> <span
                    (click)="copyToClipboard(totalSlabPartsSelected)">{{totalSlabPartsSelected
                    | number}}</span>
            </p>
            <p><strong>Shaker Parts: </strong> <span
                    (click)="copyToClipboard(totalShakerPartsSelected)">{{totalShakerPartsSelected | number}}</span>
            </p>
        </div>

        <div class="section selection-summary mat-elevation-z2">
            <p><strong>Accessories: </strong> <span
                    (click)="copyToClipboard(totalAccessoriesSelected)">{{totalAccessoriesSelected | number}}</span>
            </p>
            <p><strong>Custom Parts: </strong> <span
                    (click)="copyToClipboard(totalCustomPartsSelected)">{{totalCustomPartsSelected | number}}</span>
            </p>
        </div>
    </div>

    <table *ngIf="!loading && getData().length > 0" mat-table #productionOrdersSort="matSort" matSort
        (matSortChange)="sortChange($event)" [dataSource]="getProductionPartsNotCartonedDataSource()"
        class="mat-elevation-z0 table">
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <ng-container matColumnDef="productionDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Production Date </th>
            <td mat-cell *matCellDef="let element" class="left-border {{element.classType}}">
                <span class="productionDate {{element.classType}}">{{element.productionDate | date}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="mustShipDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> No Del Before </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.mustShipDate | date}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="plannedShipDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Planned Ship Date </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.plannedShipDate | date}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="customerNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer # </th>
            <td mat-cell *matCellDef="let element">
                {{element.customerNumber}}
            </td>
        </ng-container>

        <ng-container matColumnDef="salesOrderNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> SO # </th>
            <td mat-cell *matCellDef="let element"> {{element.salesOrderNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="productionOrderNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PO # </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.productionOrderNumber" class="poNumber {{element.productionStatusColor}}">
                    {{element.productionOrderNumber}}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="materialSupplier">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Material Supplier </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="needToDisplayMaterialSupplier(element)" class="material-supplier"
                    [ngClass]="element.materialSupplierColor">
                    {{element.materialSupplierStr}}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="moldingLength">
            <th mat-header-cell *matHeaderCellDef> Molding Length </th>
            <td mat-cell *matCellDef="let element">&nbsp; {{element.friendlyMoldingLength}} &nbsp;</td>
        </ng-container>

        <ng-container matColumnDef="channelGroove">
            <th mat-header-cell *matHeaderCellDef> Channel Groove </th>
            <td mat-cell *matCellDef="let element">&nbsp; {{element.friendlyChannelGroove}} &nbsp;</td>
        </ng-container>

        <ng-container matColumnDef="trainNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Train </th>
            <td mat-cell *matCellDef="let element"> {{element.trainNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="cartNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cart </th>
            <td mat-cell *matCellDef="let element"> {{element.cartNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="productionLocationStr">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
            <td mat-cell *matCellDef="let element"> {{element.productionLocationStr}} </td>
        </ng-container>

        <ng-container matColumnDef="customerName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer</th>
            <td mat-cell *matCellDef="let element"> {{element.customerName}} </td>
        </ng-container>

        <ng-container matColumnDef="community">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Project</th>
            <td mat-cell *matCellDef="let element"> {{element.community}} </td>
        </ng-container>

        <ng-container matColumnDef="apartmentNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Apt #</th>
            <td mat-cell *matCellDef="let element"> {{element.apartmentNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Color</th>
            <td mat-cell *matCellDef="let element"> {{element.color}} </td>
        </ng-container>

        <ng-container matColumnDef="style">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Style</th>
            <td mat-cell *matCellDef="let element"> {{element.style}} </td>
        </ng-container>

        <ng-container matColumnDef="totalParts">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Parts</th>
            <td mat-cell *matCellDef="let element"> {{element.totalParts}} </td>
        </ng-container>

        <ng-container matColumnDef="expedited">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Expedited</th>
            <td mat-cell *matCellDef="let element"> {{element.expedited | active}} </td>
        </ng-container>

        <ng-container matColumnDef="lastStatusRecorded">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Friendly Status </th>
            <td mat-cell *matCellDef="let element"> {{element.friendlyStatus}} </td>
        </ng-container>

        <ng-container matColumnDef="isLockedIn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>LockedIn </th>
            <td mat-cell *matCellDef="let element"> {{element.isLockedIn | active}} </td>
        </ng-container>

        <ng-container matColumnDef="isAudit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Audit </th>
            <td mat-cell *matCellDef="let element"> {{element.isAudit | active}} </td>
        </ng-container>

        <ng-container matColumnDef="isHot">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Hot</th>
            <td mat-cell *matCellDef="let element"> {{element.isHot | active}} </td>
        </ng-container>

        <ng-container matColumnDef="isMultiColorUnit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Multi Color</th>
            <td mat-cell *matCellDef="let element"> {{element.isMultiColorUnit | active}} </td>
        </ng-container>

        <ng-container matColumnDef="isFusion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fusion </th>
            <td mat-cell *matCellDef="let element"> {{element.isFusion | active}} </td>
        </ng-container>

        <ng-container matColumnDef="isPalletized">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pallet</th>
            <td mat-cell *matCellDef="let element"> {{element.isPalletized | active}} </td>
        </ng-container>

        <ng-container matColumnDef="accessoriesOnly">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Accessories Only</th>
            <td mat-cell *matCellDef="let element">
                {{element.isAccessoriesOnly | active}}
            </td>
        </ng-container>

        <ng-container matColumnDef="shipMethod">
            <th mat-header-cell *matHeaderCellDef> Ship Method</th>
            <td mat-cell *matCellDef="let element"> {{element.shipMethod}} </td>
        </ng-container>

        <ng-container matColumnDef="schedulerNotes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Notes </th>
            <td mat-cell *matCellDef="let element" class="right-border {{element.classType}}">
                {{element.schedulerNotes}}
            </td>
        </ng-container>
    </table>
</mat-card>