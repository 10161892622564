import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { ScheduledUnit } from '../models/scheduled-unit';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MultipleScheduledUnits } from '../models/multiple-scheduled-units';
import { ProductionDateSummary } from '../models/production-date-summary';
import { ScheduledUnitsBySalesOrder } from '../models/scheduled-units-by-salesorder';
import { ExportToProductionAudit, ExportToProductionFilter } from '../models/export-to-production-audit';
import { JobStatus } from '../models/job-status';
import { ProductionSummary } from '../models/production-summary';
import { ShippingAddress } from '../models/shipping-address';
import { ExportToProductionResult } from '../models/export-to-production-result';
import { FormattedScheduledUnit } from '../models/formatted-scheduled-unit';
import { OrderFreshDesk, OrderStateFreshDesk } from '../models/fresh-desk';
import { ProductionPartsNotCartoned } from '../models/production-parts-not-cartoned';
import { GlobalSetting } from '../models/global-setting';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(
    private config: ConfigService,
    private http: HttpClient) { }

  getShippingAddress(uniqueUnitId: number): Observable<ShippingAddress> {
    const url = this.config.buildURL(`schedule/shippingAddress`);
    const params = new HttpParams().set('uniqueUnitId', `${uniqueUnitId}`);

    return this.http.get<ShippingAddress>(url, { params });
  }

  getShipMethods(): Observable<string[]> {
    const url = this.config.buildURL(`schedule/getShipMethods`);
    return this.http.get<string[]>(url);
  }

  getScheduledUnits(productionLocationId: number, productionDate: Date, build: boolean = true, finalqa: boolean = false): Observable<FormattedScheduledUnit[]> {
    const url = this.config.buildURL(`schedule`);
    const params = new HttpParams().set('productionLocationId', `${productionLocationId}`)
      .set('productionDate', moment(productionDate).format('YYYY-MM-DD'))
      .set('build', `${build}`)
      .set('finalqa', `${finalqa}`);
    return this.http.get<FormattedScheduledUnit[]>(url, { params });
  }

  getScheduledUnitsBySalesOrderNumber(productionLocationId: number, productionDate: Date): Observable<ScheduledUnitsBySalesOrder[]> {
    const url = this.config.buildURL(`schedule/bySalesOrderNumber`);
    const params = new HttpParams().set('productionLocationId', `${productionLocationId}`)
      .set('productionDate', moment(productionDate).format('YYYY-MM-DD'));
    return this.http.get<ScheduledUnitsBySalesOrder[]>(url, { params });
  }

  getProductionDatesSummaries(productionLocationId: number, productionDate: Date): Observable<ProductionDateSummary[]> {
    const url = this.config.buildURL(`schedule/productionDatesSummaries`);
    const params = new HttpParams().set('productionLocationId', `${productionLocationId}`)
      .set('productionDate', moment(productionDate).format('YYYY-MM-DD'));
    return this.http.get<ProductionDateSummary[]>(url, { params });
  }

  getProductionSummary(): Observable<ProductionSummary> {
    const url = this.config.buildURL(`schedule/productionSummary`);
    return this.http.get<ProductionSummary>(url);
  }

  getUnfinalizedExportedScheduledUnits(productionLocationId: number): Observable<FormattedScheduledUnit[]> {
    const url = this.config.buildURL(`schedule/unfinalizedExportedScheduledUnits`);
    const params = new HttpParams().set('productionLocationId', `${productionLocationId}`);
    return this.http.get<FormattedScheduledUnit[]>(url, { params });
  }

  exportToProduction(startTime: number, productionLocationId: number, productionDate: Date, filter: ExportToProductionFilter): Observable<ExportToProductionResult> {
    const url = this.config.buildURL(`schedule/exportSchedule`);
    const params = {
      startTime,
      productionLocationId: `${productionLocationId}`,
      productionDate: moment(productionDate).format('YYYY-MM-DD'),
      filter
    };
    return this.http.post<ExportToProductionResult>(url, params);
  }

  finalizeExportToProduction(startTime: number, productionLocationId: number, productionDate: Date, filter: ExportToProductionFilter, scheduledUnits: FormattedScheduledUnit[]): Observable<FormattedScheduledUnit[]> {
    const url = this.config.buildURL(`schedule/finalizeExportSchedule`);
    const params = {
      startTime,
      productionLocationId: `${productionLocationId}`,
      productionDate: moment(productionDate).format('YYYY-MM-DD'),
      filter,
      scheduledUnits
    };
    return this.http.post<FormattedScheduledUnit[]>(url, params);
  }

  cancelExportToProduction(startTime: number, productionLocationId: number, productionDate: Date, filter: ExportToProductionFilter, scheduledUnits: FormattedScheduledUnit[]): Observable<FormattedScheduledUnit[]> {
    const url = this.config.buildURL(`schedule/cancelExportSchedule`);
    const params = {
      startTime,
      productionLocationId: `${productionLocationId}`,
      productionDate: moment(productionDate).format('YYYY-MM-DD'),
      filter,
      scheduledUnits
    };
    return this.http.post<FormattedScheduledUnit[]>(url, params);
  }

  getWhatsNotScheduled(): Observable<ProductionPartsNotCartoned[]> {
    const url = this.config.buildURL(`schedule/whatsNotScheduled`);
    return this.http.get<ProductionPartsNotCartoned[]>(url);
  }

  getJobStatus(type: string): Observable<JobStatus> {
    const url = this.config.buildURL('schedule/getJobStatus');
    const params = new HttpParams().set('type', type);
    return this.http.get<JobStatus>(url, { params });
  }

  executeJob(type: string): Observable<void> {
    const url = this.config.buildURL('schedule/executeJob');
    return this.http.post<void>(url, { type });
  }

  getExportToProductionAudit(startTime: number, productionLocationId: number, productionDate: Date): Observable<ExportToProductionAudit> {
    const url = this.config.buildURL(`schedule/getExportToProductionAudit`);
    const params = new HttpParams().set('startTime', `${startTime}`)
      .set('productionLocationId', `${productionLocationId}`)
      .set('productionDate', moment(productionDate).format('YYYY-MM-DD'));

    return this.http.get<ExportToProductionAudit>(url, { params });
  }

  getExportedScheduleUrl(productionLocationId: number, productionDate: Date, filter: ExportToProductionFilter) {
    return `${this.config.buildURL('schedule/downloadExportedScheduleAsCSV')}?productionLocationId=${productionLocationId}&productionDate=${moment(productionDate).format('YYYY-MM-DD')}&filter=${filter}`;
  }

  resetPONumber(model: ScheduledUnit): Observable<ScheduledUnit[]> {
    const url = this.config.buildURL(`schedule/reset/${model.partialUnitId}`);
    return this.http.put<ScheduledUnit[]>(url, model);
  }

  updateScheduledUnit(model: ScheduledUnit): Observable<ScheduledUnit> {
    const url = this.config.buildURL(`schedule/${model.partialUnitId}`);
    return this.http.put<ScheduledUnit>(url, model);
  }

  updateMultipleScheduledUnits(model: MultipleScheduledUnits): Observable<MultipleScheduledUnits> {
    const url = this.config.buildURL(`schedule/update`);
    return this.http.put<MultipleScheduledUnits>(url, model);
  }

  updatePalletizedOrderStatus(salesOrderNumber: string, body: any): Observable<any> {
    const url = this.config.buildCustomerInfoURL(`api/orders/updateStatus`);
    const params = new HttpParams().set('salesOrderNumber', salesOrderNumber);

    return this.http.put<any>(url, body, { params });
  }

  getFreshDeskSyncSetting(): Observable<GlobalSetting> {
    return this.http.get<GlobalSetting>(this.config.buildURL(`schedule/freshDeskSyncSetting`));
  }

  syncNewOrdersToFreshDesk(orders: OrderFreshDesk[]): Observable<OrderStateFreshDesk[]> {
    return this.http.post<OrderStateFreshDesk[]>(this.config.buildURL(`schedule/syncOrdersInFreshDesk`), orders);
  }

  createProductionOrder(routerId: number, productionOrderId?: number, includeHotPartsOrders: boolean = false, ignoreScheduledTime: boolean = false): Observable<boolean> {
    const url = this.config.buildURL('schedule/createProductionOrder');
    const params = new HttpParams()
      .set('routerId', `${routerId}`)
      .set('productionOrderId', `${productionOrderId}`)
      .set('includeHotPartsOrders', includeHotPartsOrders)
      .set('ignoreScheduledTime', ignoreScheduledTime);

    return this.http.post<boolean>(url, null, { params });
  }

  releaseProductionOrder(routerId: number, productionOrderId: number) {
    const url = this.config.buildURL('schedule/releaseProductionOrder');
    const params = new HttpParams()
      .set('routerId', `${routerId}`)
      .set('productionOrderId', `${productionOrderId}`);

    return this.http.put<boolean>(url, null, { params });
  }

  isWorkInProgressAllowed(productionLocationId: number): Observable<boolean> {
    const url = this.config.buildURL('schedule/isWipProductionOrdersAllowed');
    const params = new HttpParams().set('productionLocationId', `${productionLocationId}`);

    return this.http.get<boolean>(url, { params });
  }

  createWorkInProgress(productionLocationId: number): Observable<boolean> {
    const url = this.config.buildURL('schedule/createWipProductionOrders');
    const params = new HttpParams().set('productionLocationId', `${productionLocationId}`);

    return this.http.post<boolean>(url, null, { params });
  }

  createHotPartsOrders(productionLocationId: number): Observable<FormattedScheduledUnit[]> {
    const url = this.config.buildURL('schedule/ExportHotPartsOrdersSchedule');
    const params = new HttpParams().set('productionLocationId', `${productionLocationId}`);

    return this.http.post<FormattedScheduledUnit[]>(url, null, { params });
  }

  rollbackProductionOrder(routerId: number, productionOrderId: number, rejectionNotes: string): Observable<boolean> {
    const url = this.config.buildURL('schedule/rollbackProductionOrder');
    const params = new HttpParams()
      .set('routerId', `${routerId}`)
      .set('productionOrderId', `${productionOrderId}`)
      .set('rejectionNotes', `${rejectionNotes}`);

    return this.http.put<boolean>(url, null, { params });
  }
}
