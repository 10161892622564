<mat-card class="mat-elevation-z2">
    <h1 class="mat-h1">
        Giben Queue <ng-container *ngIf="routerModeEnabled && router">/ {{router.name}}</ng-container>
        <div>
            <button *ngIf="productionManagerModeEnabled" [disabled]="!workInProgressAllowed || creatingWorkInProgress"
                mat-raised-button color="accent" (click)="createWorkInProgress()">
                Create Work In Progress
            </button>
            <button *ngIf="productionManagerModeEnabled" [disabled]="creatingHotPartsOrder" mat-raised-button
                color="accent" (click)="createHotPartsOrders()">
                Create Hot/ Parts Orders
            </button>
            <button mat-mini-fab color="primary" (click)="refresh()" matTooltip="Refresh">
                <mat-icon>refresh</mat-icon>
            </button>
            <button *ngIf="!fullScreenMode" mat-mini-fab color="primary" (click)="setFullScreenMode()"
                matTooltip="Fullscreen Mode">
                <mat-icon>fullscreen</mat-icon>
            </button>
            <button *ngIf="fullScreenMode" mat-mini-fab color="primary" (click)="exitFullScreenMode()"
                matTooltip="Exit Fullscreen Mode">
                <mat-icon>fullscreen_exit</mat-icon>
            </button>
        </div>
    </h1>
    <hr />

    <div class="container">
        <mat-accordion multi="true">
            <mat-expansion-panel expanded="true" class="mat-elevation-z0">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Router CIM
                    </mat-panel-title>
                    <mat-panel-description>
                        Select a production location
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="actions">
                    <mat-form-field>
                        <mat-label>Production Location</mat-label>
                        <mat-select (selectionChange)="onProductionLocationChange()" [(value)]="productionLocationId">
                            <mat-option *ngFor="let item of productionLocations" [value]="item.id">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="routerModeEnabled">
                        <mat-label>Router</mat-label>
                        <mat-select (selectionChange)="onRouterChange()" [(value)]="router">
                            <mat-option *ngFor="let item of routers" [value]="item">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="machine" [class.error]="errorGettingMachine">
                        <p>Machine</p>
                        <span>{{machine}}</span>
                    </div>
                </div>
                <div class="row" *ngIf="!loading && productionOrderStats">
                    <div *ngIf="productionOrderStats.totalCustomPartsExported >= productionOrderStats.customPartsCapacity"
                        class="alert">
                        <p>
                            Total Parts Exported equal or exceed the Custom Part Capacity. To generate new
                            production orders you must increase the Allowed Custom Part Capacity on the production
                            calendar. (EN)
                        </p>
                        <p>
                            El total de piezas exportadas iguala o supera la capacidad de piezas permitidas. Para
                            generar nuevas
                            órdenes de producción se debe aumentar la capacidad de piezas permitidas en el calendario de
                            producción. (ES)
                        </p>
                    </div>
                    <p>
                        <strong>Minimum Batch:</strong> {{productionOrderStats.minimumPanelsRequired}}
                        <strong>Small Batch:</strong> {{productionOrderStats.smallBatchPanelsRequired}}
                        <strong>Hot Orders Exported:</strong> {{productionOrderStats.hotOrdersExported}}
                        <strong>Parts Orders Exported:</strong> {{productionOrderStats.partsOrdersExported}}
                        <strong>Custom Parts Exported:</strong> {{productionOrderStats.customPartsExported}}
                        <strong>Custom Parts Capacity:</strong> {{productionOrderStats.customPartsCapacity}}
                        <strong>Custom Parts Behind:</strong> {{productionOrderStats.customPartsBehind}}
                        <strong>Custom Slab Parts Available:</strong> {{productionOrderStats.customSlabPartsAvailable}}
                        <strong>Custom Parts Available:</strong> {{productionOrderStats.customPartsAvailable}}
                        <strong>Custom Parts Backlog:</strong> {{productionOrderStats.customPartsNotExported}}
                    </p>
                    <p class="customPartsBehind warning">
                        <mat-icon aria-hidden="false" aria-label="warning" fontIcon="warning"></mat-icon>
                        <strong> Custom Parts Behind Alert.</strong>
                        <ng-container *ngFor="let item of productionOrderStats.customPartsBehindByStyle">
                            <strong>{{item.style}}:</strong> <span>{{item.total}}</span>
                        </ng-container>
                        <strong> Parts Orders Non Exported:</strong>
                        <span>{{productionOrderStats.partsOrdersNonExported}}</span>
                        <strong>Custom Parts LockedIn Non Exported:</strong>
                        <span>{{productionOrderStats.customPartsLockedInNonExported}}</span>
                        <strong>Custom Slab Parts LockedIn Non Exported:</strong>
                        <span>{{productionOrderStats.customSlabPartsLockedInNonExported}}</span>
                    </p>
                    <div class="stats">
                        <div class="stat" *ngFor="let item of productionOrderStats.routerSetupStats"
                            [class.alert]="!item.mustBeExported && item.customPartsAvailable < productionOrderStats.minimumPanelsRequired"
                            [class.next]="item.nextInQueue">
                            <p><strong>Router Setup:</strong> {{item.routerScheduleStr}}</p>
                            <p><strong>Batch:</strong> {{item.maxPartsInBatch}}</p>
                            <p><strong>Color:</strong> {{item.color}}</p>
                            <p><strong>Style:</strong> {{item.style}}</p>
                            <p><strong>Min Stack Rank:</strong> {{item.minStackRank}}</p>
                            <p><strong>Min Date Ordered:</strong> {{item.minDateOrdered | date}}</p>
                            <p><strong>Custom Parts Behind:</strong> {{item.customPartsBehind}} </p>
                            <p><strong>Custom Parts Available:</strong> {{item.customPartsAvailable}} </p>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel expanded="true" class="filters-panel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Filters
                    </mat-panel-title>
                    <mat-panel-description>
                        Filter production orders
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="filters">
                    <div class="filters-container">
                        <div>
                            <mat-form-field>
                                <input matInput placeholder="PO #" [formControl]="poNumberFilter">
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker" placeholder="Production Date"
                                    [formControl]="productionDateFilter" (dateChange)="onProductionDateFilterChange()">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Colors</mat-label>
                                <mat-select [formControl]="colorsFilter" multiple>
                                    <mat-option *ngFor="let color of colors" [value]="color.name">{{color.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-checkbox [formControl]="palletFilter">
                                Pallet
                            </mat-checkbox>
                            <mat-checkbox [formControl]="fusionFilter">
                                Fusion
                            </mat-checkbox>
                            <mat-checkbox [formControl]="productionOrderHistoryFilter">
                                Include Production Order History
                            </mat-checkbox>
                        </div>
                    </div>

                    <button mat-mini-fab matTooltip="Apply filters" (click)="applyFilters()">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div class="loading" *ngIf="loading">
        <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
    </div>

    <button class="scroll-down" mat-mini-fab color="primary" (click)="scrollToDown()" matTooltip="Scroll to Bottom">
        <mat-icon>arrow_downward</mat-icon>
    </button>

    <button class="scroll-up" mat-mini-fab color="primary" (click)="scrollToTop()" matTooltip="Scroll to Top">
        <mat-icon>arrow_upward</mat-icon>
    </button>

    <div class="reset-action-container" *ngIf="!loading && filtersApplied">
        <button mat-raised-button (click)="clearFilters()">
            <mat-icon>clear</mat-icon> Reset Production Orders Filters
        </button>
    </div>

    <h4 *ngIf="!loading && !hasProductionOrders()">No production orders to show.</h4>

    <table mat-table *ngIf="!loading && hasProductionOrders()" [dataSource]="getProductionOrdersDataSource()"
        #productionOrdersSort="matSort" matSort class="mat-elevation-z0 table">
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row [ngClass]="{'started': row.gibenStartTime}"
            *matRowDef="let row; let i = index; columns: displayedColumns;">
        </tr>

        <ng-container matColumnDef="productionDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Production Date </th>
            <td mat-cell *matCellDef="let element"> {{element.productionDateStr}}</td>
        </ng-container>

        <ng-container matColumnDef="productionOrderNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PO # </th>
            <td mat-cell *matCellDef="let element">
                <a mat-button color="accent" matTooltip="Apartments with this PO#" matTooltipPosition="above"
                    routerLink="/apartments/{{element.productionOrderNumber}}">
                    <i class="material-icons">open_in_new</i> {{element.productionOrderNumber}}
                </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
            <td mat-cell *matCellDef="let element"> {{element.color}}</td>
        </ng-container>

        <ng-container matColumnDef="styles">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Styles </th>
            <td mat-cell *matCellDef="let element"> {{element.styles}}</td>
        </ng-container>

        <ng-container matColumnDef="materialSupplier">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Material Supplier </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="needToDisplayMaterialSupplier(element)" class="material-supplier"
                    [ngClass]="element.materialSupplierColor">
                    {{element.materialSupplierStr}}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="numberOfParts">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Parts</th>
            <td mat-cell *matCellDef="let element">
                {{element.numberOfParts}}
                <span *ngIf="element.onHold" class="hold">hold</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="numberOfSheets">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Sheets</th>
            <td mat-cell *matCellDef="let element"> {{element.numberOfSheets}}</td>
        </ng-container>

        <ng-container matColumnDef="pallet">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Pallet</th>
            <td mat-cell *matCellDef="let element"> {{element.isPalletized | active}}</td>
        </ng-container>

        <ng-container matColumnDef="fusion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fusion</th>
            <td mat-cell *matCellDef="let element"> {{element.isFusion | active}}</td>
        </ng-container>

        <ng-container matColumnDef="wip">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> WIP</th>
            <td mat-cell *matCellDef="let element"> {{element.wip | active}}</td>
        </ng-container>

        <ng-container matColumnDef="notes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Notes</th>
            <td mat-cell *matCellDef="let element"> {{element.gibenNotes}}</td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
            <td mat-cell *matCellDef="let element"> {{element.friendlyStatus}}</td>
        </ng-container>

        <ng-container matColumnDef="print">
            <th mat-header-cell *matHeaderCellDef>Print</th>
            <td mat-cell *matCellDef="let element">
                <button color="accent" [disabled]="disableProductionOrderActions(element) || element.paperworkPrinted"
                    mat-icon-button matTooltip="Print Paperwork" (click)="printPaperwork(element.id)"
                    matTooltipPosition="above">
                    <mat-icon>print</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="files">
            <th mat-header-cell *matHeaderCellDef>Files</th>
            <td mat-cell *matCellDef="let element">
                <button color="accent" [disabled]="disableProductionOrderActions(element) || element.filesDownloaded"
                    mat-icon-button matTooltip="Download Files" (click)="downloadFiles(element.id)"
                    matTooltipPosition="above">
                    <mat-icon>attach_file</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="pdf">
            <th mat-header-cell *matHeaderCellDef>Pdf</th>
            <td mat-cell *matCellDef="let element">
                <button color="accent" [disabled]="disableProductionOrderActions(element) || !element.pdfFileUrl"
                    mat-icon-button matTooltip="Download Pdf File" matTooltipPosition="above"
                    (click)="downloadFile(element.pdfFileUrl)">
                    <mat-icon>picture_as_pdf</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="dwg">
            <th mat-header-cell *matHeaderCellDef>Dwg</th>
            <td mat-cell *matCellDef="let element">
                <button color="accent" [disabled]="disableProductionOrderActions(element) || !element.dwgFileUrl"
                    mat-icon-button matTooltip="Download Dwg File" matTooltipPosition="above"
                    (click)="downloadFile(element.dwgFileUrl)">
                    <mat-icon>description</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
                <button color="accent" mat-icon-button matTooltip="Edit Production Order" matTooltipPosition="above"
                    (click)="openEditProductionOrder(element.id)">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>
    </table>
</mat-card>