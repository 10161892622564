import { SelectionModel } from '@angular/cdk/collections';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { FormattedScheduledUnit } from '../../models/formatted-scheduled-unit';
import { ProductionLocation } from '../../models/production-location';
import { ProductionCalendarService } from '../../services/production-calendar.service';
import { ProductionLocationsService } from '../../services/production-locations.service';
import { ScheduleService } from '../../services/schedule.service';
import { SnackbarService } from '../../services/snackbar.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

const INVALID_LOCATION_ID = -1;
const AUTO_REFRESH = 2 * 60 * 1000; // 2 MIN

@Component({
  selector: 'app-final-qa',
  templateUrl: './final-qa.component.html',
  styleUrls: ['./final-qa.component.less']
})
export class FinalQaComponent implements OnInit, AfterViewInit, OnDestroy {

  productionLocations: ProductionLocation[] = [];
  autoRefreshIntervalId = -1;
  productionLocationId = INVALID_LOCATION_ID;
  autoRefresh = true;
  customPartsCapacity = -1;
  allowedCustomPartsCapacity = -1;
  scheduledUnitsSelection = new SelectionModel<number>(true, []);
  scheduledUnitsDataSource = new MatTableDataSource<FormattedScheduledUnit>();
  filteredScheduledUnitsDataSource = new MatTableDataSource<FormattedScheduledUnit>();
  scheduledUnitsSort: MatSort;
  lastScheduledUnitsSortState: Sort;
  beginDate = new FormControl();
  loading = false;
  fullScreenMode = false;
  suggestScheduleExecuted = false;
  allowedToggleView = false;
  allowedSuggestSchedule = false;
  maxSelectedIndex = -1;
  minSelectedIndex = -1;
  filtersApplied = false;
  mousedown = false;
  productionOrderNumbers = [];
  finalQA = true;
  viewMode = 'scheduled-units';
  displayedColumns: string[] = ['productionDate', 'productionOrderNumber', 'moldingLength', 'channelGroove', 'project', 'customer', 'schedulerNotes', 'overnightServiceType', 'apartmentNumber',
    'salesOrderNumber', 'color', 'style', 'materialSupplier', 'numberOfParts', 'partialUnitId', 'sequence', 'stackRank', 'friendlyStatus', 'audit',
    'productionLocationId', 'orderDate', 'requestedDeliveryDate', 'mustShipDate', 'expedited', 'plannedShipDate', 'transitDays', 'fedExTransitDays', 'shipMethod'];
  totalCustomPartsSelected: number;
  totalAccessoriesSelected: number;
  totalSlabPartsSelected: number;
  totalShakerPartsSelected: number;

  @ViewChild('scheduledUnitsSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.scheduledUnitsSort = ms;
    this.ngAfterViewInit();

    // trigger sort change
    if (this.scheduledUnitsSort && this.lastScheduledUnitsSortState) {
      setTimeout(() => {
        this.scheduledUnitsSort.active = this.lastScheduledUnitsSortState.active;
        this.scheduledUnitsSort.direction = this.lastScheduledUnitsSortState.direction;
        this.scheduledUnitsSort.sortChange.emit(this.lastScheduledUnitsSortState);
      });
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private scheduleService: ScheduleService,
    private snackBarService: SnackbarService,
    private productionCalendarService: ProductionCalendarService,
    private productionLocationsService: ProductionLocationsService,
  ) { }

  async ngOnInit() {
    try {
      this.showLoading();
      this.fullScreenMode = this.getFullScreenMode();
      await this.getProductionLocations();
      await this.setBeginDate(new Date());
      await this.suggestSchedule();
      this.setAutoRefresh();
    } catch (err) {
      console.error('Error loading scheduled units:', err);
    } finally {
      this.hideLoading();
    }
  }

  ngOnDestroy() {
    clearInterval(this.autoRefreshIntervalId);
    this.scheduledUnitsDataSource.disconnect();
    this.scheduledUnitsDataSource.data.length = 0;
  }

  ngAfterViewInit() {
    // sort options
    this.scheduledUnitsDataSource.sort = this.scheduledUnitsSort;
    this.filteredScheduledUnitsDataSource.sort = this.scheduledUnitsSort;
  }

  @HostListener('document:fullscreenchange', ['$event'])
  onFullScreenChange() {
    this.fullScreenMode = this.getFullScreenMode();
  }

  setAutoRefresh() {
    // set auto refresh
    this.autoRefreshIntervalId = setInterval(async () => {
      await this.refresh();
    }, AUTO_REFRESH);
  }

  async refresh() {
    if (this.scheduledUnitsViewModeActive()) {
      await this.suggestSchedule();
    }
  }

  scheduledUnitsViewModeActive() {
    return !this.loading && this.suggestScheduleExecuted && this.viewMode === 'scheduled-units';
  }

  async onProductionLocationChange(event: MatSelectChange) {
    clearInterval(this.autoRefreshIntervalId);
    this.productionLocationId = event.value;
    await this.setBeginDate(new Date());
  }

  async suggestSchedule(build: boolean = false): Promise<void> {
    if (this.productionLocationId !== INVALID_LOCATION_ID && this.beginDate.value) {
      try {
        this.clearDataSource();
        this.showLoading();
        this.disableSuggestSchedule();
        const scheduledUnits = await this.scheduleService.getScheduledUnits(this.productionLocationId, this.beginDate.value, build, this.finalQA)
          .toPromise();
        this.updateScheduledUnitsDataSource(scheduledUnits);
        this.setProductionOrderNumbers();
        this.suggestScheduleExecuted = true;
      } catch (err) {
        console.error(err);
        this.snackBarService.showError('Error getting production schedule.');
      } finally {
        this.hideLoading();
        this.enableToggleView();
        this.enableSuggestSchedule();
      }
    } else {
      this.snackBarService.showError('Please select a production location.');
    }
  }

  clearDataSource() {
    this.scheduledUnitsDataSource.data = [];
    this.filteredScheduledUnitsDataSource.data = [];
  }

  getScheduledUnitsDataSource(): MatTableDataSource<FormattedScheduledUnit> {
    return this.filtersApplied ? this.filteredScheduledUnitsDataSource : this.scheduledUnitsDataSource;
  }

  needToDisplayMaterialSupplier(e: FormattedScheduledUnit): boolean {
    const colors: string[] = ['Flour', 'Mist'];
    return e.materialSupplierColor && colors.indexOf(e.color) !== -1;
  }
  clearSelectedIndexes() {
    this.mousedown = false;
    this.minSelectedIndex = -1;
    this.maxSelectedIndex = -1;
  }

  setProductionOrderNumbers() {
    const productionOrderNumbers = new Set();
    this.scheduledUnitsDataSource.data
      .filter(p => p.productionOrderNumber)
      .forEach(p => {
        productionOrderNumbers.add(p.productionOrderNumber);
      });
    this.productionOrderNumbers = Array.from(productionOrderNumbers).map(p => {
      return { value: p };
    });
  }

  getData(): FormattedScheduledUnit[] {
    return this.getScheduledUnitsDataSource().connect().value;
  }

  findAttributeScheduledUnitId(event): number {
    let schedUnitId = 0;
    const path = event.path || (event.composedPath && event.composedPath()) || [];
    for (let i = 0; i < path.length; i++) {
      const el = path[i];
      if (el.tagName === 'TR' && el.getAttribute('sched-unit-id')) {
        schedUnitId = parseInt(el.getAttribute('sched-unit-id'), 10);
        break;
      }
    }
    return schedUnitId;
  }

  scrollToTop() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollTop) {
      elem.scrollTop = 0;
    }
  }

  scrollToDown() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollHeight) {
      elem.scrollTop = elem.scrollHeight;
    }
  }

  enableToggleView() {
    this.allowedToggleView = true;
  }

  disableToggleView() {
    this.allowedToggleView = false;
  }

  updateScheduledUnitsDataSource(scheduledUnits: FormattedScheduledUnit[]) {
    this.scheduledUnitsDataSource.data = scheduledUnits;
  }

  enableSuggestSchedule() {
    this.allowedSuggestSchedule = true;
  }

  disableSuggestSchedule() {
    this.allowedSuggestSchedule = false;
  }

  scheduledUnitsSortChange(e: Sort) {
    this.lastScheduledUnitsSortState = e;
  }

  async setBeginDate(date: Date) {
    try {
      const productionCalendar = await this.productionCalendarService.getProductionCalendarBy(this.productionLocationId, date).toPromise();
      this.customPartsCapacity = productionCalendar.customPartsCapacity;
      this.allowedCustomPartsCapacity = productionCalendar.allowedCustomPartsCapacity;
    } catch (err) {
      console.error('Error getting production calendar:', err);
      this.customPartsCapacity = -1;
      this.allowedCustomPartsCapacity = -1;
    } finally {
      this.beginDate.setValue(date);
    }
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }
  getFullScreenMode(): boolean {
    return this.document.fullScreen || this.document.mozFullScreen || this.document.webkitIsFullScreen;
  }

  toggleAutoRefresh(e: MatSlideToggleChange) {
    clearInterval(this.autoRefreshIntervalId);
    this.autoRefresh = e.checked;
    if (this.autoRefresh) {
      this.setAutoRefresh();
    }
  }

  exitFullScreenMode() {
    const elem: any = this.document;
    const methodToBeInvoked = elem.exitFullscreen || elem.webkitExitFullscreen
      || elem.mozCancelFullScreen
      || elem.msExitFullscreen;

    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  setFullScreenMode() {
    const elem: any = this.document.documentElement;
    const methodToBeInvoked = elem.requestFullscreen || elem.webkitRequestFullScreen
      || elem.mozRequestFullscreen
      || elem.msRequestFullscreen;
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  getProductionLocations(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.productionLocationsService.getActiveProductionLocations().subscribe((productionLocations: ProductionLocation[]) => {
        this.productionLocations = productionLocations;
        const defaultLocation = productionLocations.find(p => p.isDefault);
        this.productionLocationId = defaultLocation !== undefined ? defaultLocation.id : INVALID_LOCATION_ID;
        resolve();
      },
        err => {
          console.error(err);
          this.snackBarService.showError('Error getting production locations.');
          reject();
        });
    });
  }
}
