import { Component, OnInit, Inject, HostListener, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { EditProductionOrderComponent } from '../production-orders/edit-production-order/edit-production-order.component';
import { ProductionOrdersService } from '../../services/production-orders.service';
import { ProductionLocationsService } from '../../services/production-locations.service';
import { ProductionOrder } from '../../models/production-order';
import { ProductionOrderStats } from '../../models/production-order-stats';
import { ProductionOrderFiles } from '../../models/production-order-file';
import { ProductionLocation } from '../../models/production-location';
import { AuthService } from '../../services/auth.service';
import { ProductionCalendarService } from '../../services/production-calendar.service';
import { ScheduleService } from '../../services/schedule.service';
import { SnackbarService } from '../../services/snackbar.service';
import { Color } from '../../models/color';
import { ProductionStatus } from '../../models/production-status';
import { RoutersService } from '../../services/routers.service';
import { Router } from '../../models/router';
import { Subscription } from 'rxjs';
import { UserRole } from '../../models/user-role';

const INVALID_LOCATION_ID = -1;
const AUTO_REFRESH = 2 * 60 * 1000; // 2 MIN
const ALLOWED_ROLES = [UserRole.Admin, UserRole.ProductionManager];

@Component({
  selector: 'app-giben',
  templateUrl: './giben.component.html',
  styleUrls: ['./giben.component.less']
})
export class GibenComponent implements OnInit, AfterViewInit, OnDestroy {

  loading = false;
  filtersApplied = false;
  fullScreenMode = false;
  routerModeEnabled = false;
  creatingWorkInProgress = false;
  workInProgressAllowed = false;
  creatingHotPartsOrder = false;
  productionManagerModeEnabled = false;
  machine = 'No IP address to show.';
  errorGettingMachine = false;
  router: Router;
  routers: Router[] = [];
  autoRefreshIntervalId: number = -1;
  poNumberFilter = new FormControl();
  productionDateFilter = new FormControl();
  colorsFilter = new FormControl();
  fusionFilter = new FormControl(false);
  palletFilter = new FormControl(false);
  productionOrderHistoryFilter = new FormControl(false);
  productionStatuses = [`${ProductionStatus[ProductionStatus.ExportedToProduction]}`, `${ProductionStatus[ProductionStatus.OptiCutProcessingCompleted]}`, `${ProductionStatus[ProductionStatus.RouterCimProcessed]}`, `${ProductionStatus[ProductionStatus.ReadyForGiben]}`, `${ProductionStatus[ProductionStatus.StartPanelsCut]}`];

  colors: Color[] = [];
  productionOrderStats: ProductionOrderStats;
  productionLocationId = INVALID_LOCATION_ID;
  productionLocations: ProductionLocation[] = [];

  displayedColumns: string[] = ['productionDate', 'productionOrderNumber', 'color', 'styles', 'materialSupplier', 'numberOfParts', 'numberOfSheets', 'pallet', 'fusion', 'wip', 'notes', 'status', 'print', 'files', 'pdf', 'dwg', 'action'];

  productionOrdersSubscription: Subscription;
  productionOrdersDataSource = new MatTableDataSource<ProductionOrder>();
  filteredProductionOrdersDataSource = new MatTableDataSource<ProductionOrder>();
  productionOrdersSort: MatSort;

  @ViewChild('productionOrdersSort', { read: MatSort }) set matSort(ms: MatSort) {
    this.productionOrdersSort = ms;
    this.ngAfterViewInit();
  }

  constructor(
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: any,
    private authService: AuthService,
    private routersService: RoutersService,
    private snackBarService: SnackbarService,
    private scheduleService: ScheduleService,
    private productionOrdersService: ProductionOrdersService,
    private productionCalendarService: ProductionCalendarService,
    private productionLocationsService: ProductionLocationsService,
  ) { }

  async ngOnInit() {
    this.showLoading();
    try {
      await this.setDefaultValues();
      await this.getProductionOrderStats();
      await this.getProductionOrders();
      await this.setWorkInProgressAllowed();

      this.applyFilters();

      this.autoRefreshIntervalId = setInterval(() => {
        this.refresh().catch(err => {
          console.error(err);
          this.snackBarService.showError('Error refreshing the Giben information. Please reload the page.');
        });
      }, AUTO_REFRESH);
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting the Giben information. Please reload the page.');
    }

    this.hideLoading();
  }

  async setDefaultValues() {
    this.enableProductionManagerMode();
    this.enableRouterMode();
    this.setIsDefaultFullScreenMode();
    await this.getColors();
    await this.getProductionLocations();
    if (this.routerModeEnabled) {
      await this.getDefaultRouter();
      await this.getRouters();
      this.selectDefaultRouter();
    }
    this.setMachine();
  }

  ngAfterViewInit() {
    // sort options
    this.productionOrdersDataSource.sort = this.productionOrdersSort;
    this.filteredProductionOrdersDataSource.sort = this.productionOrdersSort;
  }

  ngOnDestroy() {
    clearInterval(this.autoRefreshIntervalId);
    // clear production orders data and subscription
    if (this.productionOrdersSubscription) {
      this.productionOrdersSubscription.unsubscribe();
    }
    this.productionOrdersDataSource.disconnect();
    this.productionOrdersDataSource.data.length = 0;
  }

  async refresh() {
    this.showLoading();

    await this.getProductionOrderStats();
    await this.getProductionOrders();
    await this.setWorkInProgressAllowed();
    this.applyFilters();

    this.hideLoading();
  }

  @HostListener('document:fullscreenchange', ['$event'])
  onFullScreenChange() {
    this.setIsDefaultFullScreenMode();
  }

  selectDefaultRouter() {
    const routerId = this.authService.getRouter();
    this.router = this.routers.find(r => r.id === routerId);
  }

  setIsDefaultFullScreenMode() {
    this.fullScreenMode = this.document.fullScreen || this.document.mozFullScreen || this.document.webkitIsFullScreen;
  }

  setFullScreenMode() {
    const elem: any = this.document.documentElement;
    const methodToBeInvoked = elem.requestFullscreen || elem.webkitRequestFullScreen
      || elem.mozRequestFullscreen
      || elem.msRequestFullscreen;
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  exitFullScreenMode() {
    const elem: any = this.document;
    const methodToBeInvoked = elem.exitFullscreen || elem.webkitExitFullscreen
      || elem.mozCancelFullScreen
      || elem.msExitFullscreen;

    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  onProductionDateFilterChange() {
    if (!this.productionDateFilter.valid) {
      this.snackBarService.showWarning('Invalid production date.');
      this.productionDateFilter.setValue('');
    }
  }

  needToDisplayMaterialSupplier(e: ProductionOrder): boolean {
    const colors: string[] = ['Flour', 'Mist'];
    return e.materialSupplierId && colors.indexOf(e.color) !== -1;
  }

  async getColors() {
    try {
      this.colors = await this.productionCalendarService.getColors().toPromise();
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting colors.');
    }
  }

  async onProductionLocationChange() {
    this.showLoading();

    if (this.routerModeEnabled) {
      await this.getRouters();
      this.router = this.routers[0];
      this.setRouter();
    }
    this.setMachine();
    await this.getProductionOrderStats();
    await this.getProductionOrders();
    this.applyFilters();

    this.hideLoading();
  }

  async onRouterChange() {
    this.showLoading();

    this.setRouter();
    this.setMachine();
    await this.getProductionOrderStats();
    await this.getProductionOrders();
    this.applyFilters();

    this.hideLoading();
  }

  filterByPONumber(): ProductionOrder[] {
    return this.filteredProductionOrdersDataSource.data.filter(u => u.productionOrderNumber && u.productionOrderNumber.toLowerCase().includes(this.poNumberFilter.value.toLowerCase()));
  }

  filterByProductionDate(): ProductionOrder[] {
    return this.filteredProductionOrdersDataSource.data.filter(u => u.productionDate && this.equals(this.productionDateFilter.value, u.productionDate));
  }

  filterByColors(): ProductionOrder[] {
    const colors: string[] = this.colorsFilter.value;
    const productionOrders: ProductionOrder[] = [];
    for (const color of colors) {
      const r = this.filteredProductionOrdersDataSource.data.filter(u => u.color && u.color.toLowerCase() === color.toLowerCase());
      productionOrders.push(...r);
    }
    return productionOrders;
  }

  filterByFusion(): ProductionOrder[] {
    return this.filteredProductionOrdersDataSource.data.filter(u => u.isFusion);
  }

  filterByPallet(): ProductionOrder[] {
    return this.filteredProductionOrdersDataSource.data.filter(u => u.isPalletized);
  }

  filterByProductionOrderHistory(): ProductionOrder[] {
    if (!this.productionOrderHistoryFilter.value) {
      return this.filteredProductionOrdersDataSource.data.filter(c => this.productionStatuses.includes(c.status));
    }
    return this.filteredProductionOrdersDataSource.data;
  }

  equals(date1: string | Date, date2: string | Date): boolean {
    const a = new Date(date1);
    const b = new Date(date2);
    return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
  }

  getProductionOrdersDataSource(): MatTableDataSource<ProductionOrder> {
    return this.filtersApplied ? this.filteredProductionOrdersDataSource : this.productionOrdersDataSource;
  }

  applyFilters() {
    this.filteredProductionOrdersDataSource.data = this.productionOrdersDataSource.data;

    if (this.poNumberFilter.valid && this.poNumberFilter.value) {
      this.filteredProductionOrdersDataSource.data = this.filterByPONumber();
      this.filtersApplied = true;
    }
    if (this.productionDateFilter.valid && this.productionDateFilter.value) {
      this.filteredProductionOrdersDataSource.data = this.filterByProductionDate();
      this.filtersApplied = true;
    }
    if (this.colorsFilter.valid && this.colorsFilter.value && this.colorsFilter.value.length) {
      this.filteredProductionOrdersDataSource.data = this.filterByColors();
      this.filtersApplied = true;
    }
    if (this.palletFilter.valid && this.palletFilter.value) {
      this.filteredProductionOrdersDataSource.data = this.filterByPallet();
      this.filtersApplied = true;
    }
    if (this.fusionFilter.valid && this.fusionFilter.value) {
      this.filteredProductionOrdersDataSource.data = this.filterByFusion();
      this.filtersApplied = true;
    }
    if (this.productionOrderHistoryFilter.valid) {
      this.filteredProductionOrdersDataSource.data = this.filterByProductionOrderHistory();
      this.filtersApplied = true;
    }
  }

  clearFilters() {
    this.poNumberFilter.setValue('');
    this.productionDateFilter.setValue('');
    this.colorsFilter.setValue('');
    this.palletFilter.setValue(false);
    this.fusionFilter.setValue(false);
    this.productionOrderHistoryFilter.setValue(false);
    this.filteredProductionOrdersDataSource.data = [];
    this.filtersApplied = false;
  }

  enableRouterMode() {
    const routerId = this.authService.getRouter();
    this.routerModeEnabled = routerId != null;
  }

  enableProductionManagerMode() {
    this.productionManagerModeEnabled = this.authService.isUserInRoles(ALLOWED_ROLES);
  }

  disableProductionOrderActions(p: ProductionOrder): boolean {
    const statuses: string[] = [`${ProductionStatus[ProductionStatus.ExportedToProduction]}`, `${ProductionStatus[ProductionStatus.OptiCutProcessingCompleted]}`, `${ProductionStatus[ProductionStatus.RouterCimProcessed]}`];
    return statuses.indexOf(p.status) !== -1;
  }

  async getDefaultRouter() {
    try {
      const routerId = this.authService.getRouter();
      const router = await this.routersService.getRouter(routerId).toPromise();
      this.productionLocationId = router.productionLocationId;
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting default router.');
    }
  }

  async getRouters() {
    try {
      this.routers = await this.routersService.getRoutersByProductionLocation(this.productionLocationId).toPromise();
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting routers.');
    }
  }

  openEditProductionOrder(id: number) {
    const productionOrder = this.productionOrdersDataSource.data.find(p => p.id === id);

    if (productionOrder) {
      const dialogRef = this.dialog.open(EditProductionOrderComponent, {
        width: '480px',
        data: {
          routerId: this.router?.id,
          routers: this.routers,
          productionOrder
        }
      });

      dialogRef.afterClosed().subscribe((productionOrder: ProductionOrder) => {
        if (productionOrder) {
          this.refresh();
          this.snackBarService.showSuccess('The production order was updated successfully.');
        }
      });
    }
  }

  async setWorkInProgressAllowed() {
    try {
      this.workInProgressAllowed = await this.scheduleService.isWorkInProgressAllowed(this.productionLocationId).toPromise();
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting work in progress allowed.');
    }
  }

  async createWorkInProgress() {
    try {
      this.creatingWorkInProgress = true;
      const result = await this.scheduleService.createWorkInProgress(this.productionLocationId).toPromise();
      if (result) {
        this.refresh();
        this.snackBarService.showSuccess('Work in progress was created successfully.');
      } else {
        this.snackBarService.showWarning('Check your production configurations or WIP has already been created.');
      }
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error creating work in progress. Please try again.');
    } finally {
      this.creatingWorkInProgress = false;
    }
  }

  async createHotPartsOrders() {
    try {
      this.creatingHotPartsOrder = true;
      const result = await this.scheduleService.createHotPartsOrders(this.productionLocationId).toPromise();
      if (result && result.length > 0) {
        this.refresh();
        this.snackBarService.showSuccess('Hot/ Parts orders was created successfully.');
      } else {
        this.snackBarService.showWarning('No Hot/ Parts orders was created.');
      }
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error creating Hot/ Parts orders. Please try again.');
    } finally {
      this.creatingHotPartsOrder = false;
    }
  }

  async getProductionLocations() {
    try {
      this.productionLocations = await this.productionLocationsService.getActiveProductionLocations().toPromise();
      const defaultLocation = this.productionLocations.find(p => p.isDefault);
      this.productionLocationId = defaultLocation !== undefined ? defaultLocation.id : INVALID_LOCATION_ID;
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting production locations.');
    }
  }

  async getProductionOrders() {
    this.clearDataSource();
    try {
      const productionOrders = await (this.routerModeEnabled ? this.getProductionOrdersByRouter() : this.getProductionOrdersByLocation());
      this.updateProductionOrdersDataSource(productionOrders);
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting production orders.');
    }
  }

  async getProductionOrderStats() {
    try {
      this.productionOrderStats = await this.productionOrdersService.getProductionOrderStatsByRouterId(this.productionLocationId, this.router?.id).toPromise<ProductionOrderStats>();
    } catch (err) {
      console.error(err);
      this.productionOrderStats = null;
      this.snackBarService.showError('Error getting production order stats.');
    }
  }

  async getProductionOrdersByLocation(): Promise<ProductionOrder[]> {
    return await this.productionOrdersService.getProductionOrdersByLocation(this.productionLocationId).toPromise();
  }

  async getProductionOrdersByRouter(): Promise<ProductionOrder[]> {
    return await this.productionOrdersService.getProductionOrdersByRouter(this.router?.id).toPromise();
  }

  setMachine() {
    this.errorGettingMachine = false;
    this.machine = this.routerModeEnabled ? this.getIPAddress() : this.authService.getMachine();
    if (!this.machine) {
      this.errorGettingMachine = true;
      this.machine = 'No IP address to show.';
      this.snackBarService.showError('Error setting machine. Please contact your supervisor. (EN) <br/> Error al configurar la direcci&oacute;n IP. Por favor contacte a su supervisor. (ES)');
    }
  }

  setRouter() {
    this.authService.setRouter(this.router?.id);
  }

  getIPAddress(): string {
    const router = this.routers.find(c => c.id === this.router?.id);
    return router?.ipAddress;
  }

  updateProductionOrdersDataSource(productionOrders: ProductionOrder[]) {
    this.productionOrdersDataSource.data = productionOrders;
  }

  clearDataSource() {
    this.productionOrdersDataSource.data = [];
    this.filteredProductionOrdersDataSource.data = [];
  }

  hasProductionOrders() {
    return this.getProductionOrdersDataSource().data.length > 0;
  }

  scrollToTop() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollTop) {
      elem.scrollTop = 0;
    }
  }

  scrollToDown() {
    const elem: any = this.document.getElementById('sidenav-content');
    if (elem && elem.scrollHeight) {
      elem.scrollTop = elem.scrollHeight;
    }
  }

  showLoading() {
    this.loading = true;
  }

  hideLoading() {
    this.loading = false;
  }

  async downloadFiles(id: number) {
    const machine = this.routerModeEnabled ? this.getIPAddress() : this.authService.getMachine();
    if (!machine) {
      this.snackBarService.showError('We can\'t download the production order files without IP address. Please have your supervisor contact IT. (EN) <br/> No podemos descargar los archivos de la orden de producción sin una dirección IP. Pídale a su supervisor que se comunique con TI. (ES)');
      return;
    }
    try {
      const result = await this.productionOrdersService.getFiles(id, machine).toPromise<ProductionOrderFiles>();
      if (!result.downloaded) {
        this.refresh();
        this.snackBarService.showSuccess('Production order files was sent to download successfully.');
      } else {
        this.snackBarService.showWarning('The production order files was downloaded already.');
      }
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error getting production order files.');
    }
  }

  async printPaperwork(id: number): Promise<void> {
    const machine = this.routerModeEnabled ? this.getIPAddress() : this.authService.getMachine();
    if (!machine) {
      this.snackBarService.showError('We can\'t print the paperwork without IP address. Please have your supervisor contact IT. (EN) <br/> No podemos imprimir la documentaci&oacute;n sin la direcci&oacute;n IP. P&iacute;dale a su supervisor que se comunique con TI. (ES)');
      return;
    }
    try {
      const productionOrder = await this.productionOrdersService.getProductionOrder(id).toPromise<ProductionOrder>();
      if (productionOrder.paperworkPrinted) {
        this.snackBarService.showWarning('The paperwork was printed already.');
        return;
      }
      const result = await this.productionOrdersService.printPaperwork(id, machine).toPromise<boolean>();
      if (result) {
        this.refresh();
        this.snackBarService.showSuccess('Paperwork was sent to print successfully.');
      }
    } catch (err) {
      console.error(err);
      this.snackBarService.showError('Error printing the paperwork. Please try again.');
    }
  }

  downloadFile(url: string) {
    window.open(url, '_blank');
  }
}
