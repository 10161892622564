<h1 class="mat-h1">
  Final QA
  <div>
    <mat-slide-toggle checked="true" labelPosition="before" (change)="toggleAutoRefresh($event)">
      Auto Refresh
    </mat-slide-toggle>
    <button mat-mini-fab color="primary" (click)="refresh()" matTooltip="Refresh">
      <mat-icon>refresh</mat-icon>
    </button>
    <button *ngIf="!fullScreenMode" mat-mini-fab color="primary" (click)="setFullScreenMode()"
      matTooltip="Fullscreen Mode">
      <mat-icon>fullscreen</mat-icon>
    </button>
    <button *ngIf="fullScreenMode" mat-mini-fab color="primary" (click)="exitFullScreenMode()"
      matTooltip="Exit Fullscreen Mode">
      <mat-icon>fullscreen_exit</mat-icon>
    </button>
  </div>
</h1>
<hr />
<mat-accordion>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Schedule
      </mat-panel-title>
      <mat-panel-description>
        Select
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="actions">
      <mat-form-field>
        <mat-label>Production Location</mat-label>
        <mat-select (selectionChange)="onProductionLocationChange($event)" [value]="productionLocationId">
          <mat-option *ngFor="let item of productionLocations" [value]="item.id">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="customPartsCapacity!==-1">
        <input matInput readonly placeholder="Custom Parts Capacity" type="number" [value]="customPartsCapacity">
      </mat-form-field>
      <mat-form-field *ngIf="allowedCustomPartsCapacity!==-1">
        <input matInput readonly placeholder="Allowed Custom Parts Capacity" type="number"
          [value]="allowedCustomPartsCapacity">
      </mat-form-field>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<div class="container">
  <div class="loading" *ngIf="loading">
    <mat-spinner diameter="40" color="accent"> </mat-spinner> <span> Loading</span>
  </div>

  <button class="scroll-down" mat-mini-fab color="primary" (click)="scrollToDown()" matTooltip="Scroll to Bottom">
    <mat-icon>arrow_downward</mat-icon>
  </button>

  <button class="scroll-up" mat-mini-fab color="primary" (click)="scrollToTop()" matTooltip="Scroll to Top">
    <mat-icon>arrow_upward</mat-icon>
  </button>

  <h3 *ngIf="!loading && scheduledUnitsViewModeActive() && getData().length === 0">
    No scheduled units to show.
  </h3>

  <table *ngIf="scheduledUnitsViewModeActive() && getData().length > 0" mat-table
    [dataSource]="getScheduledUnitsDataSource()" #scheduledUnitsSort="matSort" matSort class="mat-elevation-z0 table"
    (matSortChange)="scheduledUnitsSortChange($event)">
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"
      attr.sched-unit-id="{{row.partialUnitId}}" [class.is-retail]="row.isRetail" [class.expedited]="row.isExpedited">
    </tr>

    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Project </th>
      <td mat-cell *matCellDef="let element"> {{element.project}}</td>
    </ng-container>

    <ng-container matColumnDef="customer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer </th>
      <td mat-cell *matCellDef="let element">{{element.customer}}</td>
    </ng-container>

    <ng-container matColumnDef="schedulerNotes">
      <th mat-header-cell *matHeaderCellDef> Notes</th>
      <td mat-cell *matCellDef="let element">
        <p *ngIf="element.schedulerNotes" class="notes truncate">{{element.schedulerNotes}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="overnightServiceType">
      <th mat-header-cell *matHeaderCellDef> Overnight</th>
      <td mat-cell *matCellDef="let element">
        <span class="truncate">{{element.overnightServiceType}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="apartmentNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Apt #</th>
      <td mat-cell *matCellDef="let element"> {{element.apartmentNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="salesOrderNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> SO #</th>
      <td mat-cell *matCellDef="let element"> {{element.salesOrderNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="partialUnitId">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let element">
        <span class="type hot" *ngIf="element.isHot">H</span>
        <span class="type overnight" *ngIf="element.isOvernight">O</span>
        <span class="type multicolor" *ngIf="element.isMultiColor">M</span>
        <span class="type parts" *ngIf="element.isPartsOrder">P</span>
        <span class="type accessories" *ngIf="element.isAccessories">A</span>
        <span class="type regular" *ngIf="element.isRegularOrder">R</span>
        <span class="type locked-in" *ngIf="element.isLockedIn">L</span>
        <span class="type sample" *ngIf="element.isTestUnit">S</span>
        <span class="type fusion" *ngIf="element.isFusion">F</span>
        <span class="type palletized" *ngIf="element.isPalletized">B</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="orderDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Date </th>
      <td mat-cell *matCellDef="let element">{{element.orderDateStr}}</td>
    </ng-container>

    <ng-container matColumnDef="productionDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Production Date </th>
      <td mat-cell *matCellDef="let element" class="left-border {{element.classType}}">
        <span class="productionDate {{element.classType}}">{{element.productionDateStr}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="productionLocationId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
      <td mat-cell *matCellDef="let element"> {{element.productionLocationStr}} </td>
    </ng-container>

    <ng-container matColumnDef="sequence">
      <th mat-header-cell *matHeaderCellDef> Seq </th>
      <td mat-cell *matCellDef="let element"> {{element.sequence}} </td>
    </ng-container>

    <ng-container matColumnDef="stackRank">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Stack Rank </th>
      <td mat-cell *matCellDef="let element"> {{element.stackRank}} </td>
    </ng-container>

    <ng-container matColumnDef="requestedDeliveryDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Req Del Date </th>
      <td mat-cell *matCellDef="let element">{{element.requestedDeliveryDateStr}}</td>
    </ng-container>

    <ng-container matColumnDef="mustShipDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>No Del Before</th>
      <td mat-cell *matCellDef="let element">{{element.mustShipDateStr}}</td>
    </ng-container>

    <ng-container matColumnDef="expedited">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Expedited</th>
      <td mat-cell *matCellDef="let element">{{element.isExpedited | active}}</td>
    </ng-container>

    <ng-container matColumnDef="transitDays">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Transit Days</th>
      <td mat-cell *matCellDef="let element">{{element.transitDays}}</td>
    </ng-container>

    <ng-container matColumnDef="fedExTransitDays">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>FedEx Transit Days</th>
      <td mat-cell *matCellDef="let element">{{element.fedExTransitDays}}</td>
    </ng-container>

    <ng-container matColumnDef="color">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Color</th>
      <td mat-cell *matCellDef="let element"> {{element.color}} </td>
    </ng-container>

    <ng-container matColumnDef="style">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Style</th>
      <td mat-cell *matCellDef="let element"> {{element.style}} </td>
    </ng-container>

    <ng-container matColumnDef="materialSupplier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Material Supplier </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="needToDisplayMaterialSupplier(element)" class="material-supplier"
          [ngClass]="element.materialSupplierColor">
          {{element.materialSupplierStr}}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="numberOfParts">
      <th mat-header-cell *matHeaderCellDef> Parts</th>
      <td mat-cell *matCellDef="let element"> &nbsp; {{element.numberOfParts}} &nbsp; </td>
    </ng-container>

    <ng-container matColumnDef="audit">
      <th mat-header-cell *matHeaderCellDef> Audit</th>
      <td mat-cell *matCellDef="let element"> {{element.isAuditStr}} </td>
    </ng-container>

    <ng-container matColumnDef="friendlyStatus">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Friendly Status</th>
      <td mat-cell *matCellDef="let element"> {{element.friendlyStatus}} </td>
    </ng-container>

    <ng-container matColumnDef="productionOrderNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> PO # </th>
      <td mat-cell *matCellDef="let element">
        <span class="poNumber {{element.productionStatusColor}}">{{element.productionOrderNumber}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="moldingLength">
      <th mat-header-cell *matHeaderCellDef> Molding Length </th>
      <td mat-cell *matCellDef="let element">&nbsp; {{element.friendlyMoldingLength}} &nbsp;</td>
    </ng-container>

    <ng-container matColumnDef="channelGroove">
      <th mat-header-cell *matHeaderCellDef> Channel Groove </th>
      <td mat-cell *matCellDef="let element">&nbsp; {{element.friendlyChannelGroove}} &nbsp;</td>
    </ng-container>

    <ng-container matColumnDef="plannedShipDate">
      <th mat-header-cell *matHeaderCellDef> Expected Delivery Date </th>
      <td mat-cell *matCellDef="let element">{{element.plannedShipDateStr}}</td>
    </ng-container>

    <ng-container matColumnDef="shipMethod">
      <th mat-header-cell *matHeaderCellDef> Ship Method</th>
      <td mat-cell *matCellDef="let element"> {{element.shipMethod}} </td>
    </ng-container>
  </table>

</div>